export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    format: {
      type: String,
      required: false,
    },
  },
  computed: {
    formattedDate() {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      const dateObj = new Date(this.date);
      const month = monthNames[dateObj.getMonth()];
      const day = String(dateObj.getDate()).padStart(2, '0');
      const year = dateObj.getFullYear();
      const output = month  + '\n'+ day  + ', ' + year;

      return output;
      //return this.$d(new Date(this.date), this.format, this.$store.state.country);
    },
  },
};
