import { render, staticRenderFns } from "./CheckoutTopSection.vue?vue&type=template&id=005c2ebc&"
import script from "./CheckoutTopSection.js?vue&type=script&lang=js&"
export * from "./CheckoutTopSection.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CheckoutTopSection.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fspa%2Fsrc%2Fcomponents%2Fcheckout%2FCheckoutTopSection%2FCheckoutTopSection.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports