import { render, staticRenderFns } from "./PageProductDetail.vue?vue&type=template&id=6f228f41&scoped=true&"
import script from "./PageProductDetail.js?vue&type=script&lang=js&"
export * from "./PageProductDetail.js?vue&type=script&lang=js&"
import style0 from "./PageProductDetail.scss?vue&type=style&index=0&id=6f228f41&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f228f41",
  null
  
)

/* custom blocks */
import block0 from "./PageProductDetail.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fspa%2Fsrc%2Fcomponents%2Fproductdetail%2FPageProductDetail%2FPageProductDetail.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports