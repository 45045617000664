export default {
  props: {
    money: Object,
  },
  computed: {
    formattedMoney() {
      return this.amount.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      })
      //return this.$n(this.amount, 'currency', this.$store.state.country);
    },
    amount() {
      if (this.money) {
        return this.money.centAmount / (10 ** this.money.fractionDigits);
        //return this.money.centAmount/10000;
      }
      return 0;
    },
    currency() {
      if (this.money) {
        return this.money.currencyCode;
      }
      return '';
    },

  },
};
