import { render, staticRenderFns } from "./ShippingRate.vue?vue&type=template&id=15a0ffb4&"
import script from "./ShippingRate.js?vue&type=script&lang=js&"
export * from "./ShippingRate.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ShippingRate.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fspa%2Fsrc%2Fcomponents%2Fcheckout%2FShippingRate%2FShippingRate.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports