// eslint-disable-next-line no-console
// console.log("using host:", process.env.VUE_APP_CT_API_HOST);
let localConfig = {};
if(process.env.VUE_APP_LOCAL_NVIZION_CONFIG){
  localConfig = require(process.env.VUE_APP_LOCAL_NVIZION_CONFIG).default;
}

export default {
  ct: {
    auth: {
      host:
        process.env.VUE_APP_CT_AUTH_HOST || 
        "https://auth.europe-west1.gcp.commercetools.com",
      projectKey:
        process.env.VUE_APP_CT_PROJECT_KEY || "nvizion-internal",
      credentials: {
        clientId:
          process.env.VUE_APP_CT_CLIENT_ID ||
          "GDtvR1KqWUnIYRDLOobuwRy_",
        clientSecret:
          process.env.VUE_APP_CT_CLIENT_SECRET ||
          "ZqrWmXzVugjTnOeXdCdnXZAUl95D7Hi8",
      },
      // Nviz_Storefront_v1
      // manage_my_profile:sunrise-spa create_anonymous_token:sunrise-spa" +
      //       " manage_my_payments:sunrise-spa view_products:sunrise-spa manage_my_orders:sunrise-spa" +
      //       " manage_my_shopping_lists:sunrise-spa
      scopes: [
        process.env.VUE_APP_CT_SCOPE ||
        "view_products:nvizion-internal manage_tax_categories:nvizion-internal view_cart_discounts:nvizion-internal manage_products:nvizion-internal manage_order_edits:nvizion-internal view_messages:nvizion-internal manage_customers:nvizion-internal view_orders:nvizion-internal manage_my_profile:nvizion-internal manage_cart_discounts:nvizion-internal manage_my_shopping_lists:nvizion-internal view_stores:nvizion-internal manage_stores:nvizion-internal view_tax_categories:nvizion-internal manage_my_orders:nvizion-internal view_types:nvizion-internal manage_states:nvizion-internal view_categories:nvizion-internal manage_types:nvizion-internal manage_my_payments:nvizion-internal view_order_edits:nvizion-internal manage_payments:nvizion-internal manage_discount_codes:nvizion-internal manage_shipping_methods:nvizion-internal introspect_oauth_tokens:nvizion-internal manage_subscriptions:nvizion-internal create_anonymous_token:nvizion-internal manage_orders:nvizion-internal view_discount_codes:nvizion-internal",
      ],
    },
    api:
      process.env.VUE_APP_CT_API_HOST ||
      "https://api.europe-west1.gcp.commercetools.com",
  },
  languages: {
    en: "English",
    //de: "Deutsch",
  },
  countries: {
    IN: "India",
    //US: "Deutschland",
  },
  formats: {
    number: {
      // DE: {
      //   currency: {
      //     style: "currency",
      //     currency: "EUR",
      //     currencyDisplay: "symbol",
      //   },
      // },
      IN: {
        currency: {
          style: "currency",
          currency: "INR",
        },
      },
    },
    datetime: {
      IN: {
        short: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
      },
      // DE: {
      //   short: {
      //     year: "numeric",
      //     month: "short",
      //     day: "numeric",
      //   },
      // },
    },
  },
  categories: {
    salesExternalId: "6",
  },
  facetSearches: [
    {
      name: "size",
      type: "enum",
      label: {
        it: "Size",
        de: "Größe",
        en: "Size",
      },
    },
    {
      name: "price",
      type: "enum",
      component: "price",
      label: {
        it: "Price",
        de: "Price",
        en: "Price",
      },
    },
    {
      name: "color",
      type: "text",
      component: "colors",
      label: {
        de: "Farbe",
        it: "Color",
        en: "Color",
      },
    },
    // {
    //   name: "type",
    //   type: "text",
    //   label: {
    //     de: "Type",
    //     it: "Type",
    //     en: "Type",
    //   },
    // },
    // {
    //   name: "designer",
    //   type: "enum",
    //   component: "designer",
    //   label: {
    //     it: "Designer",
    //     de: "Designer",
    //     en: "Designer",
    //   },
    // },
  ],
  detailAttributes: [
    "designer",
    "colorFreeDefinition",
    "size",
    "gender",
    "articleNumberManufacturer",
    "brand",
    "color",
    "fabric-note",
    "style-note",
    "sleeve-length",
    "fit",
    "fabric",
    "type",
    "orientation-type",
    "colour",
    "material",
    "design",
    "compartments",
    "pocket-specifications",
    "water-resistance",
    "laptop-compatible",
    "style",
    "cap-size",
    "wash-care",
    "book-size",
    "no-pages",
    "paper",
    "binding",
    "page-style",
  ],
  variantSelector: ["color", "size"],
  variantInProductName: ["size"],
  ...localConfig
};
