/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import {
  withToken,
  fetchJson,
  makeConfig,
  baseUrl,
} from "./api";
//import { v4 as uuid } from "uuid";

const orders = {
  createItem: withToken(
    ({ id, version, orderNumber}, accessToken) =>{
      //console.log('id, version, orderNumber', id, version, orderNumber)
      fetchJson(`${baseUrl}/orders/${id}`, {
        ...makeConfig(accessToken),
        method: "POST",
        body: JSON.stringify({
          version,
          actions: [
            {
              action: "setOrderNumber",
              orderNumber: orderNumber
            },
          ],
        }),
      })
    }
  ),
};

export default orders;
