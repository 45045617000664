import gql from 'graphql-tag';
import authMixin from '../../../mixins/authMixin';

export default {
  mixins: [authMixin],
  data: () => ({
    me: null,
  }),
  watch: {
    showLoggedIn(){
      if(this.$store.state.authenticated && this.me?.customer?.addresses.length>0 && !this.$store.state.defaultAddress){
        this.$store.dispatch('setDefaultAddress', this.me.customer.addresses[0]);
      }
    },
  },
  computed: {
    showLoggedIn: (vm) => vm.$store.state.authenticated && vm.me,
  },
  apollo: {
    me: {
      query: gql`
        query loginButtonMe {
          me {
            customer {
              id
              firstName
              addresses {
                title
                firstName
                lastName
                streetName
                additionalStreetInfo
                phone
                postalCode
                city
                state
                country
                email
              }
            }
          }
        }`,
      skip: (vm) => !vm.$store.state.authenticated,
    },
  },
};
