import gql from 'graphql-tag';

export default {
  props: {
    sku: {
      type: String,
      required: true,
    },
    quickview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    product: null,
  }),
  computed: {
    productImages() {
      return this.product.masterData.current.variant.images;
    },
    productImage() {
      const img = this.product.masterData.current.variant.images[0]?.url;
      if (img) {
        return img.replace(/_medium.jpg$/, '_large.jpg');
      }
      return undefined;
    },
    zoomerImages() {
      const imageInfos = this.productImages.map((image, index) => ({
        id: index,
        url: image.url,
      }));
      return {
        //thumbs: imageInfos,
        normal_size: imageInfos,
        large_size: imageInfos,
      };
    },
    zoomerOptions() {
      return {
        zoomFactor: 3,
        pane: "pan",
        hoverDelay: 300,
        namespace: "zoomer-bottom",
        move_by_click: true,
        scroll_items: 4, //this.galleryThumbnailsCount
        choosed_thumb_border_color: "#FEC14E",
        scroller_position: "bottom",
        zoomer_pane_position: "right"
      };
    },
    galleryThumbnailsCount() {
      //return Math.min(this.productImages.length, 4);
      return this.productImages.length;
    },
  },
  watch: {
    // product() {
    //   setTimeout(
    //     () => {
    //       console.log('count', this.galleryThumbnailsCount)
    //       $('.product-big-img-style').removeClass("hidden");
    //       // $(this.$refs.easyzoom).easyZoom(this.zoomerOptions);
    //       $('.thumb-list img:nth-child(2)').trigger('click');
    //       console.log($('.thumb-list img').length)
    //       if(this.galleryThumbnailsCount < 2){
    //         $('.thumb-list').addClass("hidelist");
    //       }
    //       else if(this.galleryThumbnailsCount <= 4 ){
    //         $('.zoomer-control').addClass("hideNav");
    //       }
    //     }, 300,
    //   );
    // },
  },
  apollo: {
    product: {
      query: gql`
        query ProductGallery($sku: String!) {
          product(sku: $sku) {
            id
            masterData {
              current {
                variant(sku: $sku) {
                  sku
                  images {
                    url
                  }
                }
              }
            }
          }
        }`,
      variables() {
        return {
          sku: this.sku,
        };
      },
    },
  },
};
