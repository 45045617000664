import BaseFooter from '../BaseFooter/BaseFooter.vue';
// import FooterLinks from '../FooterLinks/FooterLinks.vue';
// import FooterMarketing from '../FooterMarketing/FooterMarketing.vue';

import authMixin from '../../../mixins/authMixin';

export default {
  components: {
    BaseFooter,
    // FooterLinks,
    // FooterMarketing,
  },
  computed: {
    activeTab() {
      return this.$route.name;
    },
  },
  mixins: [authMixin],
};

