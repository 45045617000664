import gql from "graphql-tag";
import cartMixin from "../../../mixins/cartMixin";
import CheckoutTopSection from "../CheckoutTopSection/CheckoutTopSection.vue";
import OrderOverview from "../OrderOverview/OrderOverview.vue";
import BillingDetails from "../BillingDetails/BillingDetails.vue";
import CART_FRAGMENT from "../../Cart.gql";
import MONEY_FRAGMENT from "../../Money.gql";
import ADDRESS_FRAGMENT from "../../Address.gql";
import { locale } from "../../common/shared";
import payments from "../../../api/payments";
import orders from "../../../api/orders";

export default {
  components: {
    CheckoutTopSection,
    OrderOverview,
    BillingDetails,
  },
  mixins: [cartMixin],
  data: () => ({
    me: null,
    shippingMethod: null,
    billingAddress: null,
    shippingAddress: null,
    orderComplete: false,
    validBillingForm: false,
    validShippingForm: true,
    showError: false,
    orderNumberValue: String,
    paymentReferenceNumber: String,
    transactionReferenceNumber: String,
    paymentFailed: false
  }),
  methods: {
    setValidBillingForm(valid) {
      this.validBillingForm = valid;
    },
    setValidShippingForm(valid) {
      this.validShippingForm = valid;
    },
    updateBilling(billingDetails) {
      this.billingAddress = billingDetails;
    },
    updateShipping(shippingDetails) {
      this.shippingAddress = shippingDetails;
    },
    updateShippingMethod(shippingId) {
      this.shippingMethod = shippingId;
    },
    placeOrder() {
      if (this.validBillingForm && this.validShippingForm) {
        this.updateMyCart([
          {
            setBillingAddress: {
              address: this.billingAddress,
            },
          },
        ])
          .then((result) => {
            //console.log('abc', this.me,this.me.activeCart)
            this.me.activeCart = result.data.updateMyCart;
            if (!this.shippingAddress) {
              return this.updateMyCart([
                {
                  setShippingAddress: {
                    address: this.billingAddress,
                  },
                },
              ]);
            }
            return this.updateMyCart([
              {
                setShippingAddress: {
                  address: this.shippingAddress,
                },
              },
            ]);
          })
          .then((result) => {
            let thisElem = this;
            //console.log('result.data', result.data.updateMyCart)
            thisElem.me.activeCart = result.data.updateMyCart;
            let cartData = result.data.updateMyCart
            //console.log('result.data', result.data)
            // console.log('cartData', cartData)
            let fraction = 1;
            let fractionDigits = 3 //cartData.totalPrice.fractionDigits;
            for(var i = 0; i<fractionDigits; i++){fraction = Number(String(fraction) + "0")}

            var rzp1 = new window.Razorpay({
              //key: "rzp_test_yx2uH5sORnyYxc", //dev
              key: "rzp_live_YpGpVmY0zjA28v",
              amount: Number(cartData.totalPrice.centAmount),
              name: cartData.billingAddress.firstName + ' ' + cartData.billingAddress.lastName,
              currency: cartData.totalPrice.currencyCode,
            description: "New Order",
              image: "https://www.nvizionsolutions.com/images/our-logos/NvizionSolutions-2020-Logo-small.png",
              order_id: this.order_id,
              "prefill": {
                  "name": cartData.billingAddress.firstName + ' ' + cartData.billingAddress.lastName,
                  "email": cartData.billingAddress.email,
              },
              "theme": {
                  "color": "#00a3de"
              },
              "handler": function (response){
                console.log('response', response);
                console.log('response', response)
                return thisElem.processOrder(cartData, response);
              },
            });
            rzp1.open();
            rzp1.on('payment.failed', function (response){
                window.scrollTo(0,0);
                //console.log('payment failed', response.error.metadata.payment_id);
                this.transactionReferenceNumber = response.error.metadata.payment_id
                this.paymentFailed = true;
                //thisElem.me.activeCart.payment.id = response.error.metadata.payment_id;
                return false
            })
          })
          .then(() => {
            console.log('id')
            //
          });
      } else {
        this.showError = true;
      }
    },
    offlineOrder() {
      if (this.validBillingForm && this.validShippingForm) {
        this.updateMyCart([
          {
            setBillingAddress: {
              address: this.billingAddress,
            },
          },
        ])
          .then((result) => {
            //console.log('abc', this.me,this.me.activeCart)
            this.me.activeCart = result.data.updateMyCart;
            if (!this.shippingAddress) {
              return this.updateMyCart([
                {
                  setShippingAddress: {
                    address: this.billingAddress,
                  },
                },
              ]);
            }
            return this.updateMyCart([
              {
                setShippingAddress: {
                  address: this.shippingAddress,
                },
              },
            ]);
          })
          .then((result) => {
            console.log('result', result)
            let thisElem = this;
            //console.log('result.data', result.data.updateMyCart)
            thisElem.me.activeCart = result.data.updateMyCart;
            let cartData = result.data.updateMyCart
            //console.log('result.data', result.data)
            // console.log('cartData', cartData)
            let fraction = 1;
            let fractionDigits = 3 //cartData.totalPrice.fractionDigits;
            for(var i = 0; i<fractionDigits; i++){fraction = Number(String(fraction) + "0")}

            return thisElem.processOfflineOrder(cartData, null);
          })
      } else {
        this.showError = true;
      }
    },
    createPayment(cartData, paymentInfo) {
      //console.log('createPayment', cartData, paymentInfo)
      return Promise.resolve(this.$store.state.payment)
        .then(
          (payment) =>
            payment?.id && payments.deleteItem(payment)
        )
        .then(() =>
          payments
            .createItem({
              amountPlanned: {
                currencyCode: cartData.totalPrice.currencyCode,
                centAmount: cartData.totalPrice.centAmount,
              },
              paymentMethodInfo: {
                //paymentInterface: process.env.VUE_APP_ADYEN_INTEGRATION,
                paymentInterface: 'razorpay',
                method: "Razorpay",
                name: {
                  en: "Razorpay",
                },
              },
              interactionId : paymentInfo.razorpay_payment_id,
              paymentStatus : {
                interfaceCode : 'Paid'
              },
              transactions:[{
                type : 'Authorization',
                amount : {
                  currencyCode: cartData.totalPrice.currencyCode,
                  centAmount: cartData.totalPrice.centAmount
                },
                interactionId : paymentInfo.razorpay_payment_id
              }]
            })
            .then((payment) => {
              if (payment.satusCode) {
                return Promise.reject();
              }
              //console.log('payment', payment)
              this.$store.dispatch("setPayment", payment);
              return payment;
            })
        );
    },
    processOrder(cartData, paymentInfo){
      let thisElem = this;
      this.createPayment(cartData, paymentInfo)
        .then((payment) => {
          this.updateMyCart([
              {
                addPayment: {
                  payment: {
                    id: payment.id,
                  },
                },
              },
            ])
            .then(() => {
              this.orderComplete = true;
              //console.log('this.me.activeCart', thisElem.me.activeCart)
              thisElem.createMyOrder()
              .then((orderDetails) => {
                window.scrollTo(0,0);
                const orderData = orderDetails?.data?.createMyOrderFromCart
                thisElem.setOrderNumber(orderData?.id, 1)
                this.paymentReferenceNumber = paymentInfo.razorpay_payment_id
                this.orderComplete = true;
              })
            })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("why what?", error);
          this.error = true;
        });
    },
    createOfflinePayment(cartData, paymentInfo) {
      return Promise.resolve(this.$store.state.payment)
        .then(
          (payment) =>
            payment?.id && payments.deleteItem(payment)
        )
        .then(() =>
          payments
            .createItem({
              amountPlanned: {
                currencyCode: cartData.totalPrice.currencyCode,
                centAmount: cartData.totalPrice.centAmount,
              },
              paymentMethodInfo: null,
              interactionId : paymentInfo.id,
              paymentStatus : {
                interfaceCode : 'NA'
              },
              transactions:[]
            })
            .then((payment) => {
              if (payment.satusCode) {
                return Promise.reject();
              }
              //console.log('payment', payment)
              this.$store.dispatch("setPayment", payment);
              return payment;
            })
        );
    },
    processOfflineOrder(cartData){
      let thisElem = this;
      var now = new Date();
      let paymentInfo = {payment: { id: `Offer-${now.getMonth().toString()}`}}
      console.log('processOfflineOrder', cartData)
      this.createOfflinePayment(cartData, paymentInfo)
        .then((payment) => {
          this.updateMyCart([
              {
                addPayment: {
                  payment: {
                    id: payment.id,
                  },
                },
              },
            ])
            .then(() => {
              this.orderComplete = true;
              //console.log('this.me.activeCart', thisElem.me.activeCart)
              thisElem.createMyOrder()
              .then((orderDetails) => {
                window.scrollTo(0,0);
                const orderData = orderDetails?.data?.createMyOrderFromCart
                thisElem.setOrderNumber(orderData?.id, 1)
                this.paymentReferenceNumber = null
                this.orderComplete = true;
              })
            })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("why what?", error);
          this.error = true;
        });
    },
    setOrderNumber(id,version){
      var now = new Date();
      var timestamp = now.getMonth().toString() + now.getDate().toString() + now.getFullYear().toString() + now.getHours().toString()+ now.getMinutes().toString()+ now.getSeconds().toString() + now.getUTCMilliseconds().toString();
      let orderNumber = "NVIZ-"+timestamp;
      
      console.log('orderNumber', orderNumber);
      

      orders
      .createItem({
        id: id,
        version: version,
        orderNumber: orderNumber
      })
      .then(() => {
        this.orderNumberValue = orderNumber
      })
    }
  },
  apollo: {
    me: {
      query: gql`
        query me($locale: Locale!) {
          me {
            activeCart {
              ...CartFields
            }
          }
        }
        ${CART_FRAGMENT}
        ${MONEY_FRAGMENT}
        ${ADDRESS_FRAGMENT}
      `,
      variables() {
        return {
          locale: locale(this),
        };
      },
      skip() {
        return !locale(this);
      },
    },
  },
};
