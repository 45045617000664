import { render, staticRenderFns } from "./CartLikePriceDetail.vue?vue&type=template&id=6683257f&scoped=true&"
import script from "./CartLikePriceDetail.js?vue&type=script&lang=js&"
export * from "./CartLikePriceDetail.js?vue&type=script&lang=js&"
import style0 from "./style.css?vue&type=style&index=0&id=6683257f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6683257f",
  null
  
)

/* custom blocks */
import block0 from "./CartLikePriceDetail.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fspa%2Fsrc%2Fcomponents%2Fcommon%2FCartLike%2FCartLikePriceDetail%2FCartLikePriceDetail.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports