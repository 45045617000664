import { render, staticRenderFns } from "./SignUpForm.vue?vue&type=template&id=1c5f5149&"
import script from "./SignUpForm.js?vue&type=script&lang=js&"
export * from "./SignUpForm.js?vue&type=script&lang=js&"
import style0 from "./SignUpForm.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./SignUpForm.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fspa%2Fsrc%2Fcomponents%2Flogin%2FSignUpForm%2FSignUpForm.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports