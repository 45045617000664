import gql from 'graphql-tag';
import { required, email } from 'vuelidate/lib/validators';
import customerMixin from '../../../mixins/customerMixin';
import BaseInput from '../../common/form/BaseInput/BaseInput.vue';
import BaseForm from '../../common/form/BaseForm/BaseForm.vue';
import LoadingSpinner from "../../common/LoadingSpinner/LoadingSpinner.vue";
import ServerError from '../../common/form/ServerError/ServerError.vue';

export default {
  components: {
    BaseInput, BaseForm, LoadingSpinner, ServerError
  },
  data: () => ({
    me: null,
    form: {
      firstName: '',
      email: '',
      lastName: '',
      title:'',
      streetName: '',
      additionalStreetInfo: '',
      postalCode: '',
      city: '',
      state: '',
      country: '',
    },
    edit:false,
    operationSuccess: false
  }),
  mixins: [customerMixin],
  methods: {
    editMode(){
      console.log('editMode', this.edit)
      this.edit = true
    },
    editCancel(){
      console.log('editCancel', this.edit)
      this.edit = false
    },
    updateCustomerProfile() {
      let addressData = this.form
      delete addressData.addresses
      delete addressData.version
      delete addressData.id
      delete addressData.id
      
      if(this.me.customer.addresses.length>0){
        addressData.id = this.me.customer.addresses[0].id
        delete addressData.__typename;

        return this.updateMyCustomer([
          { changeEmail: { email: this.form.email } },
          { setFirstName: { firstName: this.form.firstName } },
          { setLastName: { lastName: this.form.lastName } },
          { changeAddress: { address: addressData, addressId: addressData.id } },
          { setDefaultBillingAddress: { addressId: addressData.id } },
          { setDefaultShippingAddress: { addressId: addressData.id } },
        ]).then(() => {
          this.edit = false
          this.$store.dispatch('setDefaultAddress', addressData);
          this.operationSuccess = true
        });
      }
      else{
        delete addressData.__typename;

        return this.updateMyCustomer([
          { changeEmail: { email: this.form.email } },
          { setFirstName: { firstName: this.form.firstName } },
          { setLastName: { lastName: this.form.lastName } },
          { addAddress: { address: addressData, addressId: addressData.id } },
          { setDefaultBillingAddress: { addressId: addressData.id } },
          { setDefaultShippingAddress: { addressId: addressData.id } },
        ]).then(() => {
          this.edit = false
          this.$store.dispatch('setDefaultAddress', addressData);
          this.operationSuccess = true
        });
      }


      // setTimeout(function(){
      //   return this.updateMyCustomer([
      //     { setDefaultBillingAddress: { addressId: addressData.id } },
      //   ]);
      // }, 3000)



      ///Object.assign(addressData, {"country": "IN"})
      //Object.assign(addressData, {"__typename": "Address"})

      // return this.updateMyCustomer([
      //   { changeEmail: { email: this.form.email } },
      //   { setFirstName: { firstName: this.form.firstName } },
      //   { setLastName: { lastName: this.form.lastName } },
      //   { changeAddress: { address: addressData, addressId: addressData.id } },
      // ]);
    },
    getErrorMessage({ code }) {
      this.operationSuccess = false
      if (code === 'InvalidCurrentPassword') {
        return this.$t('invalidPassword');
      }
      return this.$t('unknownError');
    },
    country(){
      return this.$store.state.country
    }
  },
  watch: {
    me() {
      let rawData = {}
      rawData = { ...this.me.customer, ...this.me.customer.addresses[0] }
      //console.log('rawData', rawData)
      this.form = rawData
      delete rawData.addresses
      if( !this.form.country || this.form.country==''){
        this.form.country = this.$store.state.country
      }
    },
  },
  computed: {
    countries() {
      const configCountries = this.$Nvizion.countries;
      const countries = configCountries ? Object.entries(configCountries)
      .filter(([id])=>id===this.$route.params.country)
      : [];
      return countries.map(([id, name]) => ({ id, name }));
    },
    isLoading() {
      return this.$apollo.loading || !this.me;
    },
  },
  apollo: {
    me: {
      query: gql`
        query me {
          me {
            customer {
              id
              version
              email
              firstName
              lastName
              addresses {
                id
                title
                firstName
                lastName
                streetName
                additionalStreetInfo
                postalCode
                phone
                city
                state
                country
                email
                key
              }
            }
          }
        }`,
    },
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      streetName: { required },
      additionalStreetInfo: {},
      postalCode: { required },
      city: { required },
      phone: {},
      email: { required, email },
    },
  },
};
